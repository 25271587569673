// ToastContainer.tsx
import React from 'react';
import CustomToast from './Toast'; // Dein CustomToast-Komponentenimport

import { useDevice } from '../../context/Devicecontext';

interface ToastData {
  id: number;
  message: string;
  duration: number;
  type: 'success' | 'error' | 'warning';
}

interface ToastContainerProps {
  toasts: ToastData[];
  onRemoveToast: (id: number) => void;
}

const ToastContainer: React.FC<ToastContainerProps> = ({ toasts, onRemoveToast }) => {
  const { isMobile } = useDevice();

  return (
    <div className={`toast-container ${isMobile ? 'mobile' : 'desktop'}`}>
      {toasts.map((toast) => (
        <CustomToast
          message={toast.message}
          duration={toast.duration}
          onClose={() => onRemoveToast(toast.id)}
          type={toast.type}
        />
      ))}
    </div>
  );
};

export default ToastContainer;
