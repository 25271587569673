import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import "./Upload.css"

import FileUpload from '../../components/upload/FileUpload';

import { useUser } from '../../context/Usercontext';
import { useDevice } from '../../context/Devicecontext';

const Upload: React.FC = () => {
  const { user } = useUser();
  const { isMobile } = useDevice();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/signin'); // Umleitung auf die Startseite oder eine andere Seite
    }
  }, [user, navigate]);

  if (!user) {
    return null; // Rückgabe von null, damit kein Inhalt angezeigt wird, während weitergeleitet wird
  }

  return (
    <div className='upload-container'>
      <h1>Upload</h1>
      <FileUpload />
    </div>
  );
};

export default Upload;