import React from 'react';

import "./SidebarToggle.css"

import { useSideTopBar } from '../../../context/SideTopBarcontext';


const SidebarToggle: React.FC = () => {
    const {isSidebarExpanded, setIsSidebarExpanded} = useSideTopBar();
    
    return (
        <button onClick={() => setIsSidebarExpanded(!isSidebarExpanded)} className={`sidebar-toggle-button ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
            {isSidebarExpanded && <img src="/icons/collapse.svg" alt='einklappen'></img>}
            {!isSidebarExpanded && <img src="/icons/expand.svg" alt='ausklappen'></img>}
        </button>
    );
};

export default SidebarToggle;