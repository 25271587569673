import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

import { googleLogout } from "@react-oauth/google";

import { getUserInformation, call_logout } from '../components/auth/AuthHelper';

// Typ für die Benutzerinformationen
export interface User {
    sub: string;
    name: string;
    given_name: string;
    family_name: string;
    picture: string;
    email: string;
    email_verified: boolean;
    hd: string;
}

// Typen für das Kontext-Interface
interface UserContextType {
    user: User | null;
    login: (userData: User) => void;
    logout: () => void;
}

// Standard-Werte für den Kontext
const UserContext = createContext<UserContextType | undefined>(undefined);

// Funktionen zum Speichern und Abrufen der Benutzerinformationen im localStorage
const storeUserInLocalStorage = (user: User): void => {
    localStorage.setItem('user', JSON.stringify(user));
};

const getUserFromLocalStorage = (): User | null => {
    const userString = localStorage.getItem('user');
    return userString ? JSON.parse(userString) as User : null;
};

const removeUserFromLocalStorage = (): void => {
    localStorage.removeItem('user');
};

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);

    // Überprüfen, ob der Benutzer noch eingeloggt ist, wenn die App lädt (optional)
    useEffect(() => {
        const storedUser = getUserFromLocalStorage();
        if (storedUser) {
            setUser(storedUser); // Setzt den Benutzer aus dem localStorage
        }
    }, []);

    const login = (userData: User) => {
        setUser(userData);
        storeUserInLocalStorage(userData); // Speichert den Benutzer im localStorage
    }  

    const logout = () => {
        call_logout();
        googleLogout();
        setUser(null);  // Benutzer entfernen
        removeUserFromLocalStorage(); // Entfernt den Benutzer aus dem localStorage
    }

    return (
        <UserContext.Provider value={{ user, login, logout }}>
            {children}
        </UserContext.Provider>
    );
};

// Custom Hook zum Verwenden des UserContext
export const useUser = (): UserContextType => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};