import { GoogleOAuthProvider } from "@react-oauth/google";
import React from 'react';

import Auth from "../../components/auth/Auth";

const google_client_id: string = process.env.REACT_APP_GOOGLE_CLIENT_ID || "No Value configured";

const Signin: React.FC = () => {
  return (
    <div className='login-container'>
      <GoogleOAuthProvider clientId={google_client_id} onScriptLoadError={() => console.error("Google OAuth Skript konnte nicht geladen werden")}>
        <Auth/>
      </GoogleOAuthProvider>
    </div>
  );
};

export default Signin;
