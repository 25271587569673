// CustomToast.tsx
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Toast from "react-bootstrap/Toast";

import "./Toast.css";

// Definiere die Props
interface CustomToastProps {
  message: string;
  duration: number;
  onClose: () => void;
  type: 'success' | 'error' | 'warning'; // Typ für Toast-Varianten
}

const CustomToast: React.FC<CustomToastProps> = React.memo(({ message, duration, onClose, type }) => {
  const toastClass = (() => {
    switch (type) {
      case 'success':
        return 'text-bg-success';
      case 'error':
        return 'text-bg-danger';
      case 'warning':
        return 'text-bg-warning';
      default:
        return '';
    }
  })();

  return (
    <Toast
      className={`align-items-center border-0 CustomToast ${toastClass}`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      onClose={onClose}
      delay={duration}
      autohide
    >
      <div className="toast-body">
        <div className="toast-msg">
          {message}
        </div>
        <button
          type="button"
          className="btn-close btn-close-white me-2 m-auto"
          onClick={onClose}
          aria-label="Close"
        ></button>
      </div>
    </Toast>
  );
});

export default CustomToast;
