// ToastEventEmitter.ts
class ToastEventEmitter extends EventTarget {
    addToast(message: string, duration = 3000, type: 'success' | 'error' | 'warning' = 'success') {
      const event = new CustomEvent<{ id: number; message: string; duration: number; type: string }>('addToast', {
        detail: { id: Date.now(), message, duration, type },
      });
      this.dispatchEvent(event);
    }
  
    removeToast(id: number) {
      const event = new CustomEvent<number>('removeToast', { detail: id });
      this.dispatchEvent(event);
    }
  }
  
  export const toastEventEmitter = new ToastEventEmitter();
  