import React from 'react';
import "./Immobilien.css"

const Immobilien: React.FC = () => {
  return (
    <div className='immobilien-container'>
      <h1>Immobilien</h1>
    </div>
  );
};

export default Immobilien;
