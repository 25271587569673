import React from 'react';
import { Link } from 'react-router-dom';
import './TopbarLink.css';

import { useSideTopBar } from '../../../context/SideTopBarcontext';

// Definiere die Props-Typen
interface TopbarLinkProps {
  to: string;
  iconClass: string;
  label: string;
}

const TopbarLink: React.FC<TopbarLinkProps> = ({ to, iconClass, label }) => {
  const {isTopbarExpanded, setIsTopbarExpanded} = useSideTopBar();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setIsTopbarExpanded(false);
  };

  return (
    <Link to={to} onClick={handleClick} className={`sidebar-link ${isTopbarExpanded ? 'expanded' : 'collapsed'}`}>
      <i className={iconClass}></i>
      {isTopbarExpanded && <span>{label}</span>}
    </Link>
  );
};

export default TopbarLink;