import React, {useEffect, useContext } from 'react';

import './Sidebar.css';

import SidebarLink from './button/SidebarLink';
import SidebarToggle from './button/SidebarToggle';

import { useUser } from '../../context/Usercontext';
import { useSideTopBar } from '../../context/SideTopBarcontext';

import { useNavigate } from 'react-router-dom';

const Sidebar: React.FC = () => {
    const { user } = useUser();
    const {isSidebarExpanded} = useSideTopBar();
    const navigate = useNavigate();

    const handleImageClick = () => {
        navigate('/dashboard');
    };
    
    return (
        <div className={`sidebar ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
            <div className={`sidebar-logo ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
                {isSidebarExpanded && <img src="/logo/LogoGrossHell.svg" alt='Kompaso' onClick={handleImageClick}></img>}
                {!isSidebarExpanded && <img src="/logo/LogoKleinHell.svg" alt='Kmps' onClick={handleImageClick}></img>}
            </div>

            <div className={`sidebar-toggle-container ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
                <SidebarToggle/>
            </div>

            <div className='sidebar-content'>
                {user &&  
                <div className='sidebar-userinformation'>
                    <img src={`data:image/png;base64,${user.picture}`} alt=""/>
                    {isSidebarExpanded && <b>{user?.name}</b>}
                </div>
                }

                <ul className="menu">
                    <li>
                        <SidebarLink to="/dashboard" iconClass="icon icon-dashboard" label="Dev"/>
                    </li>
                    <li>
                        <SidebarLink to="/immobilien" iconClass="icon icon-immobilien" label="Immobilien"/>
                    </li>
                    {user && <li>
                        <SidebarLink to="/upload" iconClass="icon icon-upload" label="Upload"/>
                    </li>}
                    <li>
                        <SidebarLink to="/signin" iconClass="icon icon-signin" label="Sign In"/>
                    </li>
                </ul>
            </div>

            {isSidebarExpanded && 
            <div className='sidebar-bottom'>
                <p>Kompaso Digital GmbH</p>
            </div>
            }

        </div>
    );
};

export default Sidebar;
