import React from 'react';
import { Link } from 'react-router-dom';
import './SidebarLink.css';

import { useSideTopBar } from '../../../context/SideTopBarcontext';

// Definiere die Props-Typen
interface SidebarLinkProps {
  to: string;
  iconClass: string;
  label: string;
}

const SidebarLink: React.FC<SidebarLinkProps> = ({ to, iconClass, label }) => {
  const {isSidebarExpanded} = useSideTopBar();
  
  return (
    <Link to={to} className={`sidebar-link ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
      <i className={iconClass}></i>
      {isSidebarExpanded && <span>{label}</span>}
    </Link>
  );
};

export default SidebarLink;