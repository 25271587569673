import React from 'react';
import {
    Chart as ChartJS,
    DoughnutController,
    ArcElement,
    Tooltip,
    Legend,
    TooltipItem,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { ReactChart } from 'chartjs-react';

// ChartJS-Controller registrieren
ChartJS.register(DoughnutController, ArcElement, Tooltip, Legend);

// Typen für die Props definieren
interface DoughnutChartProps {
    labels: string[];
    data: number[];
    backgroundColors: string[];
    borderColors: string[];
    title?: string;
}

// Chart-Optionen
const createChartOptions = (title?: string) => ({
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        tooltip: {
            callbacks: {
                label: (tooltipItem: TooltipItem<'doughnut'>) => {
                    const label = title || "Label"; // Dynamisches Label
                    const value = tooltipItem.raw || 0;
                    return `${label}: ${value}`;
                },
            },
        },
    },
});

// Hauptkomponente für das Doughnut-Diagramm
const DoughnutChart: React.FC<DoughnutChartProps> = ({ labels, data, backgroundColors, borderColors, title }) => {
    const chartData = {
        labels: labels,
        datasets: [
            {
                label: title || 'Doughnut Chart', // Dynamischer Titel
                data: data,
                backgroundColor: backgroundColors,
                borderColor: borderColors,
                borderWidth: 1,
            },
        ],
    };

    return (
        <ReactChart
            type="doughnut"
            data={chartData}
            options={createChartOptions(title)}
        />
    );
};

export default DoughnutChart;
