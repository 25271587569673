import React, { useRef, useEffect, useState } from 'react';
import './Dashboard.css';

import FolderView from '../../components/folderview/FolderView';

import { toastEventEmitter } from '../../components/toast/ToastEmitter';

import DoughnutChart from '../../components/charts/doughnut/DoughnutChart';


const Dashboard: React.FC = () => {

    const labels = ['Hellblau', 'Türkis', 'Blau'];
    const data = [300, 50, 100];
    const backgroundColors = ['rgba(187, 213, 235, 0.6)', 'rgba(44, 198, 210, 0.6)', 'rgba(13, 170, 233, 0.6)'];
    const borderColors = ['rgba(187, 213, 235, 1)', 'rgba(44, 198, 210, 1)', 'rgba(13, 170, 233, 1)'];

    return (
        <div className='dashboard-container'>
            <h1>Dashboard</h1>
            <div className='test-chartjs'>
                <DoughnutChart
                    labels={labels}
                    data={data}
                    backgroundColors={backgroundColors}
                    borderColors={borderColors}
                    title="Test-Diagramm"
                />
            </div>
            <br></br>
            <FolderView />
            <div>
                <button onClick={() => toastEventEmitter.addToast("Operation successful!", 30000, 'success')}>
                    Show Success Toast
                </button>
                <button onClick={() => toastEventEmitter.addToast("This is a warning!", 30000, 'warning')}>
                    Show Warning Toast
                </button>
                <button onClick={() => toastEventEmitter.addToast("An error occurred!", 30000, 'error')}>
                    Show Error Toast
                </button>
            </div>
        </div>
    );
};

export default Dashboard;