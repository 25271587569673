import React from 'react';

import "./TopbarToggle.css"

import { useSideTopBar } from '../../../context/SideTopBarcontext';

const TopbarToggle: React.FC = () => {
    const {isTopbarExpanded, setIsTopbarExpanded} = useSideTopBar();

    return (
        <button onClick={() => setIsTopbarExpanded(!isTopbarExpanded)} className={`topbar-toggle-button ${isTopbarExpanded ? 'expanded' : 'collapsed'}`}>
            {!isTopbarExpanded && <img src='/icons/burger-menu.svg' alt='ausklappen'/>}
            {isTopbarExpanded && <img src='/icons/close.svg' alt='einklappen'/>}
        </button>
    );
};

export default TopbarToggle;