import React, { createContext, useContext, useEffect, useState } from 'react';

// Typen für das Context-Interface
interface SideTopBarContextType {
    isTopbarExpanded: boolean;
    setIsTopbarExpanded: React.Dispatch<React.SetStateAction<boolean>>; // Funktion hinzufügen
    
    isSidebarExpanded: boolean;
    setIsSidebarExpanded: React.Dispatch<React.SetStateAction<boolean>>; // Funktion hinzufügen
}

// Erstellen des Contexts mit einem Standardwert
const SideTopBarContext = createContext<SideTopBarContextType | undefined>(undefined);

export const SideTopProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isTopbarExpanded, setIsTopbarExpanded] = useState<boolean>(false);
    const [isSidebarExpanded, setIsSidebarExpanded] = useState<boolean>(true);

    return (
        <SideTopBarContext.Provider value={{ isTopbarExpanded, setIsTopbarExpanded, isSidebarExpanded, setIsSidebarExpanded}}>
            {children}
        </SideTopBarContext.Provider>
    );
};

// Custom Hook, um den Context einfacher verwenden zu können
export const useSideTopBar = (): SideTopBarContextType => {
    const context = useContext(SideTopBarContext);
    if (context === undefined) {
        throw new Error('useSideTopBar muss innerhalb eines DeviceProviders verwendet werden');
    }
    return context;
};
