/* Dieses Element stellt eine Auswahlzeile oben an der Seite dar */
/* Ist ein Ersatz für die Sidebar, da diese auf mobilen Geräten schlecht aussehen würde */

import React from 'react';
import './Topbar.css';

import TopbarToggle from './button/TopbarToggle';
import TopbarLink from './button/TopbarLink';

import { useUser } from '../../context/Usercontext';
import { useSideTopBar } from '../../context/SideTopBarcontext';

import { useNavigate } from 'react-router-dom';

const Topbar: React.FC = () => {
    const { user } = useUser();
    const { isTopbarExpanded } = useSideTopBar();
    const navigate = useNavigate();

    const handleImageClick = () => {
        navigate('/dashboard');
    };

    return (
        <div className={`topbar ${isTopbarExpanded ? 'expanded' : 'collapsed'}`}>
            <div className='topbar-header'>
                <div className='topbar-logo'>
                    <img src="/logo/LogoGrossHell.svg" alt='Kompaso' onClick={handleImageClick} />
                </div>
                <div className={`topbar-toggle-container ${isTopbarExpanded ? 'expanded' : 'collapsed'}`}>
                    <TopbarToggle />
                </div>
            </div>

            {isTopbarExpanded && <div className='topbar-content'>
                <ul className="menu">
                    <li>
                        <TopbarLink to="/dashboard" iconClass="" label="Dev" />
                    </li>
                    <li>
                        <TopbarLink to="/immobilien" iconClass="icon icon-immobilien" label="Immobilien" />
                    </li>
                    {user && <li>
                        <TopbarLink to="/upload" iconClass="icon icon-upload" label="Upload" />
                    </li>}
                    <li>
                        <TopbarLink to="/signin" iconClass="icon icon-signin" label="Sign In" />
                    </li>
                </ul>
            </div>}
        </div>
    );
};

export default Topbar;
