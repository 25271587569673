import React, { useState } from "react";
import { useGoogleLogin, CodeResponse, GoogleLogin } from "@react-oauth/google";

import { useUser, User } from '../../context/Usercontext';

import { call_google_login, getUserInformation } from "./AuthHelper";

import { useNavigate } from 'react-router-dom';

export default function Auth() {
  const { user, login, logout } = useUser();
  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: "openid email profile https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file",
    onSuccess: async (codeResponse: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>) => {
      await call_google_login(codeResponse);
      const data: User = await getUserInformation();
      login(data);
      navigate('/dashboard');
    },
    onError: (error) => {
      console.error('Fehler bei der Authentifizierung:', error);
    },
    onNonOAuthError: (error) => {
      console.error('Fehler:', error);
    },
  });

  return (
    <div className="google-login-container">
      {user ? (
        <button onClick={logout}>Logout {user.email}</button>
      ) : (
        <button
          onClick={() => {
            console.log("Login-Button wurde geklickt"); // Überprüfen, ob der Klick registriert wird
            googleLogin();
            // <GoogleLogin
            //   clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
            //   buttonText="Login"
            //   onSuccess={responseGoogle}
            //   onFailure={responseGoogle}
            //   cookiePolicy={'single_host_origin'}
            // />
          }}
        >
          Log in with Google
        </button>
      )}
    </div>
  );
}
